import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
// import { useLoads } from '../../api/queries/use-loads';
import { Load } from '../../types';
import { LoadProvider } from '../shared/load-context';
import { useTravelTimesQuery } from '../travel-times/use-travel-times';
import { useDrafts } from '../../api/queries/use-drafts';
import './loads2.css'
import { LoadTable2Header } from './table-components/load-table2-header';

interface LoadTable2Props {
    // columns: DisplayColumnDef<Load, string>[];
    // columns: typeof ColumnHelper<Load>.accessor;
    columns: ColumnDef<Load, any>[];
}

export const LoadTable2: React.FC<LoadTable2Props> = (props) => {
    const { columns } = props;
    // const { loads, isLoading, isFetching, refetch } = useLoads();
    const { drafts, isLoading, isFetching, refetch } = useDrafts();
    useTravelTimesQuery(isFetching);

    const table = useReactTable({
        data: drafts,
        columns,
        getCoreRowModel: getCoreRowModel(),

        getPaginationRowModel: getPaginationRowModel(), // this enables client side pagination
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        // globalFilterFn: orderGroupGlobalFilterFunc,
        // enableGlobalFilter: true,
        initialState: {
            // pagination: { pageIndex: 0, pageSize: pageSizePreference }
            pagination: { pageIndex: 0, pageSize: 100 }
        },
    });

    if (isLoading) {
        return null;
    }

    return (
        <div className='lt-base'>
            <LoadTable2Header table={table} />
            <div className='lt-body'>
                {table.getRowModel().rows.map(row => {
                    // console.log('row:', row);
                    const loadIdQuestionMark = row.original.id
                    return (
                        <LoadProvider
                            key={loadIdQuestionMark}
                            load={row.original}
                            // mutation={isEditable ? (load.id ? 'EditDraft' : 'AddDraft') : undefined}
                            mutation='EditDraft'
                            // disableConflicts={options.disableConflicts}
                            // TODO: FIX!
                            disableConflicts={true}
                        >
                            <div key={row.id} className='lt-row'>
                                {row.getVisibleCells().map(cell => (
                                    <div
                                        key={cell.id}
                                        style={{
                                            // width: cell.column.getSize(),
                                            overflow: 'hidden',
                                            // minWidth: cell.column.getSize(),
                                            // maxWidth: cell.column.getSize(),
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        </LoadProvider>
                    );
                })}
            </div>
        </div>
    );
};
