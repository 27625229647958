import React, { useState } from 'react';
import { ContentHeader } from '../shared/containers/content-header';
import { Button, Paper, Typography } from '@mui/material';
import { L } from 'harmony-language';
import { Content } from '../shared/containers/content';
import { useCarrier, usePermissions } from '../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';
import Add from '@mui/icons-material/Add';
import { useLoads2Columns } from './use-loads2-columns';
import { LoadTable2 } from './load-table2';

interface Loads2Props {

}

export const Loads2: React.FC<Loads2Props> = (props) => {
    const {  } = props;
    const canCreateOrder = usePermissions(PermissionType.CreateOrder);
    const currentUserIsCarrierOrg = useCarrier();
    const [dialogOpen, setDialogOpen] = useState(false);
    const columns = useLoads2Columns();

    return (
        <>
            <ContentHeader>
                {/* <Typography variant='h5'>{L.loads()}</Typography> */}
                <Typography variant='h5'>THIS IS REALLY DRAFTS!!!!</Typography>
                {canCreateOrder && !currentUserIsCarrierOrg &&
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setDialogOpen(true)}
                    >
                        <Add /> {L.createLoad()}
                    </Button>}
            </ContentHeader>
            <Content style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Paper sx={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <LoadTable2
                        columns={columns}
                    />
                </Paper>
            </Content>
        </>
    );
};
