import { CustomerTier, Features, PermissionType } from 'harmony-constants';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { App } from './app/app';
import { AuthenticatedRoute } from './components/authentication/authenticated-route';
import { Administration } from './components/company-management/administration';
import { AvoidanceZoneManagement } from './components/company-management/avoidance-zones/avoidance-zone-management';
import { ChecklistTemplateManagement } from './components/company-management/checklist-templates/checklist-template-management';
import { CompanyManagement } from './components/company-management/companies/company-management';
import { ContactManagement } from './components/company-management/contacts/contact-management';
import { CustomerManagement } from './components/company-management/customers/customer-management';
import { LoadingTeamManagement } from './components/company-management/loading-teams/loading-team-management';
import { LocationManagement } from './components/company-management/locations/location-management';
import { NotificationConfigManagement } from './components/company-management/notification-configs/notification-config-management';
import { PermissionsManagement } from './components/company-management/permissions/permissions-management';
import { ServiceAccountManagement } from './components/company-management/service-accounts/service-account-management';
import { SettingsManagement } from './components/company-management/settings/settings-management';
import { TractorManagement } from './components/company-management/tractors/tractor-management';
import { TrailerManagement } from './components/company-management/trailers/trailer-management';
import { UserManagement } from './components/company-management/users/user-management';
import { Dashboard } from './components/dashboard/dashboard';
import { Drafts } from './components/drafts/drafts';
import { LiveMap } from './components/live-map/live-map';
import { Loads } from './components/loads/loads';
import { EquipmentChecklistHistory } from './components/maintenance/equipment-checklist-history';
import { Maintenance } from './components/maintenance/maintenance';
import { PageNotFound } from './components/page-not-found';
import { PrivacyPolicy } from './components/privacy-policy';
import { ProductionPlanning } from './components/production-planning/production-planning';
import { Reports } from './components/reports/reports';
import SiteIssues from './components/site-issues/site-issues';
import { LoginForm } from './components/user/login-form';
import { ResetPassword } from './components/user/reset-password';
import { UserActivation } from './components/user/user-activation';
import { setupArrayUtilityFunctions } from './utils/array-utils';
import { MapDemo } from './components/map-demo';
import { StandardRouteManagement } from './components/company-management/standard-routes/standard-route-management';
import { Loads2 } from './components/loads2/loads2';

setupArrayUtilityFunctions();

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <PageNotFound />,
        children: [
            {
                // index: true,
                path: '/login',
                element: <LoginForm />
            },
            {
                path: '/logout',
                element: <LoginForm />
            },
            {
                path: '/activate-account/:uuid',
                element: <UserActivation />
            },
            {
                path: '/forgot-password',
                element: <ResetPassword />
            },
            {
                path: '/404',
                element: <PageNotFound />
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/',
                element: <AuthenticatedRoute
                    Component={<Dashboard />}
                    carrier={true} />
            },
            {
                path: '/live-map',
                element:
                    <AuthenticatedRoute
                        Component={<LiveMap />}
                        tier={CustomerTier.Freemium}
                        carrier={true}
                        feature={Features.DeliveryTracking}
                        permission={PermissionType.ViewLiveMap} />
            },
            {
                path: '/production-planning',
                element:
                    <AuthenticatedRoute
                        Component={<ProductionPlanning />}
                        feature={Features.ProductionPlanning}
                        permission={[PermissionType.ViewProductionCards]}
                        carrier={false} />
            },
            {
                path:'/loads',
                element: <AuthenticatedRoute
                    Component={<Loads />}
                    permission={[PermissionType.ViewOwningOrders]}
                    carrier={true} />
            },
            {
                path:'/loads2',
                element: <AuthenticatedRoute
                    Component={<Loads2 />}
                    permission={[PermissionType.ViewOwningOrders]}
                    carrier={true} />
            },
            {
                path:'/drafts',
                element: <AuthenticatedRoute
                    Component={<Drafts />}
                    feature={Features.Drafts}
                    permission={[PermissionType.EditOrder]}
                    carrier={false} />
            },
            {
                path:'/reports',
                element: <AuthenticatedRoute
                    Component={<Reports />}
                    feature={Features.Reports}
                    carrier={false} />
            },
            {
                path:'/maintenance',
                element: <AuthenticatedRoute
                    Component={<Maintenance />}
                    feature={Features.Checklists}
                    permission={[PermissionType.ViewChecklist]}
                    carrier={false} />
            },
            {
                path:'/maintenance-history/:type/:id',
                element: <AuthenticatedRoute
                    Component={<EquipmentChecklistHistory />}
                    feature={Features.Checklists}
                    permission={[PermissionType.ViewChecklist]}
                    carrier={false} />
            },
            {
                path:'/site-issues',
                element: <AuthenticatedRoute
                    Component={<SiteIssues />}
                    feature={Features.SiteIssues}
                    permission={[PermissionType.ViewSiteIssues]}
                    carrier={false} />
            },
            {
                path:'/manage-company/tractors',
                element: <AuthenticatedRoute
                    Component={<TractorManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewTractors]}
                    carrier={true}
                    carrierSometimes={'/manage-company/tractors'} />
            },
            {
                path:'/manage-company/trailers',
                element: <AuthenticatedRoute
                    Component={<TrailerManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewTrailers]}
                    carrier={true}
                    carrierSometimes={'/manage-company/trailers'} />
            },
            {
                path:'/manage-company/users',
                element: <AuthenticatedRoute
                    Component={<UserManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewUsers]}
                    carrier={true} />
            },
            {
                path:'/manage-company/contacts',
                element: <AuthenticatedRoute
                    Component={<ContactManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewContacts]}
                    carrier={false} />
            },
            {
                path:'/manage-company/loading-teams',
                element: <AuthenticatedRoute
                    Component={<LoadingTeamManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewLoadingTeams]}
                    carrier={false} />
            },
            {
                path:'/manage-company/locations',
                element: <AuthenticatedRoute
                    Component={<LocationManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewLocations]}
                    carrier={false} />
            },
            {
                path:'/manage-company/permissions',
                element: <AuthenticatedRoute
                    Component={<PermissionsManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewAllCompanies]}
                    carrier={false} />
            },
            {
                path:'/manage-company/customers',
                element: <AuthenticatedRoute
                    Component={<CustomerManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewCustomers]}
                    carrier={false} />
            },
            {
                path:'/manage-company/avoidance-zones',
                element: <AuthenticatedRoute
                    Component={<AvoidanceZoneManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewAvoidanceZones]}
                    carrier={false} />
            },
            {
                path:'/manage-company/standard-routes',
                element: <AuthenticatedRoute
                    Component={<StandardRouteManagement />}
                    feature={Features.StandardRoutes}
                    permission={[PermissionType.AccessAdministration]}
                    carrier={false} />
            },
            {
                path:'/manage-company/service-accounts',
                element: <AuthenticatedRoute
                    Component={<ServiceAccountManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewServiceUser]}
                    carrier={false} />
            },
            {
                path:'/manage-company/settings',
                element: <AuthenticatedRoute
                    Component={<SettingsManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.EditCompany]}
                    carrier={true} />
            },
            {
                path:'/manage-company/notification-configs',
                element: <AuthenticatedRoute
                    Component={<NotificationConfigManagement />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewNotificationConfigs]}
                    carrier={false} />
            },
            {
                path:'/manage-company/companies',
                element: <AuthenticatedRoute
                    Component={<CompanyManagement />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewAllCompanies]}
                    carrier={false} />
            },
            {
                path:'/manage-company/tractor-checklist-templates',
                element: <AuthenticatedRoute
                    Component={<ChecklistTemplateManagement equipmentType={'tractor'} />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewChecklistTemplates]}
                    carrier={false} />
            },
            {
                path:'/manage-company/trailer-checklist-templates',
                element: <AuthenticatedRoute
                    Component={<ChecklistTemplateManagement equipmentType={'trailer'} />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewChecklistTemplates]}
                    carrier={false} />
            },
            {
                path: '/map-helpers',
                element: <AuthenticatedRoute
                    Component={<MapDemo />}
                    permission={[PermissionType.AccessAdministration, PermissionType.ViewAllCompanies]}
                    carrier={false} />
            },
            {
                path:'/manage-company',
                element: <AuthenticatedRoute
                    Component={<Administration />}
                    tier={CustomerTier.Freemium}
                    permission={[PermissionType.AccessAdministration]}
                    carrier={true} />
            },
        ]
    }
]);

window.L = require('harmony-language');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
    // <StrictMode>
        <RouterProvider router={router} />
    // </StrictMode>
);

// turning strict mode on causes HereMap to show up 2x, as the map is loaded in a useEffect.
// a fix is to add this to here-map-wrapper
// const mapNode = document.getElementById(elementId);
// mapNode.replaceChildren();
// but causes issues with tagLocationButtons() in location-map and unsure how to remove them properly
