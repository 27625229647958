import { flexRender, Table } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../../types';
import { PerformanceFilter } from '../performance-filter';

interface LoadTable2HeaderProps {
    table: Table<Load>;
}

export const LoadTable2Header: React.FC<LoadTable2HeaderProps> = (props) => {
    const { table } = props;

    return (
        <div className='lt-header'>
            {table.getHeaderGroups().map(headerGroup => (
                <div key={headerGroup.id} className='lt-row'>
                    {headerGroup.headers.map(header => {
                        const canSort = header.column.getCanSort();

                        return <div
                            key={header.id}
                            style={{
                                overflow: 'hidden',
                                // position: 'sticky',
                                // still not sure this is 100% right, need to mess with when more stuff on screen
                                // in grid now
                                // minWidth: header.getSize(),
                                // maxWidth: header.getSize(),
                            }}
                        >
                            {header.isPlaceholder ? null : (
                                <>
                                    <div
                                        className={canSort ? 'cursor-pointer' : undefined}
                                        onClick={() => {
                                            if (canSort) {
                                                header.column.toggleSorting();
                                            }
                                        }}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                        <PerformanceFilter column={header.column} />
                                    ) : null}
                                </>
                            )}
                        </div>
                    })}
                </div>
            ))}
        </div>
    );
};
