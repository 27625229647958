import { Column } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Load } from '../../types';

interface PerformanceFilterProps {
    column: Column<Load, unknown>;
}

export const PerformanceFilter: React.FC<PerformanceFilterProps> = (props) => {
    const { column } = props;
    const [value, setValue] = useState('');

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        column.setFilterValue(e.target.value);
    }

    return (
        <input
            className='lt-input'
            value={value}
            onChange={onFilterChange}
            // autoComplete=''
            placeholder='Filter...'
            type='search'
            list='test'
        />
    );
};
