import React from 'react';

interface PerformanceButtonProps {
    onClick: () => void;
}

// https://developers.google.com/fonts/docs/material_symbols
// https://fonts.google.com/icons

export const PerformanceButton: React.FC<PerformanceButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button
            className='lt-button'
            onClick={onClick}
        >
            <span className='material-icons size-16'>edit</span>
        </button>
    );
};
