import React from 'react';
import { createColumnHelper } from '@tanstack/react-table'
import { Driver, Load, OrganizationLocation } from '../../types';
import { useMemo } from 'react';
import { LoadCargo, LoadDate, LoadDate2, LoadDestination, LoadDestination2, LoadDriver, LoadDriver2, LoadFirstStop, LoadFirstStop2, LoadLastCompleted, LoadLastStop, LoadLastStop2, LoadOrderNumbers2, LoadOrigin, LoadOrigin2, LoadStatus, LoadStatus2, LoadTractor, LoadTractor2, LoadTrailer, LoadTrailer2 } from '../shared/load-table/load-table-cells';
import { L } from 'harmony-language';
import { sortByDriver, sortByStatus, sortByStop, sortByStopTime } from '../shared/table/utils/sorting-functions';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { loadToFirstStop, loadToLastStop } from '../../utils/data-mapping-utils';
import { PerformanceButton } from './performance-button';
import { doesValueMatch, searchByArrivalTimeFromStop, searchByStopLocation, searchByStopLocationFromStops } from '../shared/table/utils/search-functions';
import { StopType } from 'harmony-constants';

// https://tanstack.com/table/latest/docs/guide/column-defs#column-def-types
export const useLoads2Columns = () => {
    const columnHelper = createColumnHelper<Load>();
    const { isLoading: isLoadingLocations, data: organizationLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { isLoading: isLoadingDrivers, data: drivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);

    const columns = useMemo(() => {
        const c = [
            // TODO: REMOVE
            columnHelper.accessor('id', {
                header: L.id(),
                cell: info => info.getValue(),
                size: 50,
            }),
            columnHelper.display({
                header: L.actions(),
                // header: null,
                cell: () => <PerformanceButton onClick={() => { console.log('perf button') }} />,
                // cell: () => null,
                size: 50,
            }),
            columnHelper.accessor((_, b) => b, {
                header: L.orderNumber(),
                // cell: info => info.getValue(),
                cell: () => <LoadOrderNumbers2 />,
                size: 90,
                enableSorting: false,
                filterFn: (row, _, filterValue) => {
                    const orderNumbers = [...new Set(row.original.stops.map(s => s.orderNumber).filter(x => x !== null))];
                    return orderNumbers.some(orderNumber => doesValueMatch(orderNumber, filterValue));
                },
            }),
            // accessor like this because we don't really want to return a 'known' column for these columns
            // they are computed data based on stops
            // but we need to use .accessor and not .display as we can only sort/filter with .accessor
            columnHelper.accessor((_, b) => b, {
                header: L.origin(),
                cell: () => <LoadOrigin2 />,
                size: 200,
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Origin')(rowA.original, rowB.original);
                },
                filterFn: (row, _, filterValue) => {
                    const stops = row.original.stops.filter(stop => stop.type === StopType.Origin);
                    return searchByStopLocationFromStops(stops, filterValue, organizationLocations);
                },
            }),
            columnHelper.accessor((_, b) => b, {
                header: L.destination(),
                cell: () => <LoadDestination2 />,
                size: 200,
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Destination')(rowA.original, rowB.original);
                },
                filterFn: (row, _, filterValue) => {
                    const stops = row.original.stops.filter(stop => stop.type === StopType.Destination);
                    return searchByStopLocationFromStops(stops, filterValue, organizationLocations);
                },
            }),
            columnHelper.accessor((_, b) => b, {
                header: L.firstStop(),
                cell: () => <LoadFirstStop2 />,
                size: 150,
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToFirstStop)(rowA.original, rowB.original);
                },
                filterFn: (row, _, filterValue) => {
                    const firstStop = loadToFirstStop(row.original);
                    return searchByArrivalTimeFromStop(firstStop, filterValue);
                },
            }),
            columnHelper.accessor((_, b) => b, {
                header: L.lastStop(),
                cell: () => <LoadLastStop2 />,
                size: 150,
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToLastStop)(rowA.original, rowB.original);
                },
                filterFn: (row, _, filterValue) => {
                    const lastStop = loadToLastStop(row.original);
                    return searchByArrivalTimeFromStop(lastStop, filterValue);
                },
            }),
            columnHelper.accessor((_, b) => b, {
                header: L.cargo(),
                cell: () => <LoadCargo />,
                size: 180,
                enableSorting: false,
            }),
            columnHelper.accessor('transportedByUserId', {
                header: L.driver(),
                cell: () => <LoadDriver2 />,
                size: 130,
                sortingFn: (rowA, rowB) => {
                    return sortByDriver(drivers)(rowA.original, rowB.original);
                },
            }),
            columnHelper.accessor('tractorId', {
                header: L.tractor(),
                cell: () => <LoadTractor2 />,
                size: 130,
            }),
            columnHelper.accessor('trailerId', {
                header: L.trailer(),
                cell: () => <LoadTrailer2 />,
                size: 130,
            }),
            columnHelper.accessor('status', {
                header: L.status(),
                cell: () => <LoadStatus2 />,
                size: 100,
                sortingFn: (rowA, rowB) => {
                    return sortByStatus()(rowA.original, rowB.original);
                },
            }),
            // TODO: REMOVE
            columnHelper.display({
                header: 'rows',
                cell: info => info.table.getRowCount(),
                size: 40,
            }),
        ];

        return c;
    }, [organizationLocations, drivers]);

    return columns;
}
